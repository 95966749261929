import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout"

import GlobalGuide from "../components/globalGuide"
import GlobalContact from "../components/globalContact"
import InvestmentTile from "components/InvestmentTile"

const NaszeInwestycjeComponent = ({ data }) => {
  const [status, setStatus] = useState(null)
  const [market, setMarket] = useState(null)
  const [profit, setProfit] = useState(null)

  const filterData = data => {
    if (!!status) {
      data = data.filter(
        item => status === item.node.acfInvestments.investmentFiltrStatus
      )
    }
    if (!!market) {
      data = data.filter(
        item => market === item.node.acfInvestments.investmentFiltrMarket
      )
    }
    if (!!profit) {
      data = data.filter(
        item => profit === item.node.acfInvestments.investmentFiltrProfit
      )
    }
    return data
  }

  return (
    <Layout
      seo={{
        title: "Nasze inwestycje",
      }}
      isInvest
    >
      <section className="objects-hero">
        <h1>Nasze inwestycje</h1>
      </section>

      <section className="objects-content">
        <Fade delay={150} direction={"bottom"} triggerOnce>
          <div className="container-fluid">
            <div className="objects-content__filters">
              <div className="row align-items-center">
                <div className="col-md">
                  <h3>Szukaj</h3>
                </div>
                <div className="col-md">
                  <p>Hotele</p>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="status"
                      value="operate"
                      onChange={() => {
                        status === "operate"
                          ? setStatus(null)
                          : setStatus("operate")
                      }}
                      checked={status === "operate"}
                    />
                    <span>Działające</span>
                  </label>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="status"
                      value="construction"
                      onChange={() => {
                        status === "construction"
                          ? setStatus(null)
                          : setStatus("construction")
                      }}
                      checked={status === "construction"}
                    />
                    <span>W budowie</span>
                  </label>
                </div>

                <div className="col-md">
                  <p>Rynek</p>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="market"
                      value="secondary"
                      onChange={() => {
                        market === "secondary"
                          ? setMarket(null)
                          : setMarket("secondary")
                      }}
                      checked={market === "secondary"}
                    />
                    <span>Wtórny</span>
                  </label>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="market"
                      value="original"
                      onChange={() => {
                        market === "original"
                          ? setMarket(null)
                          : setMarket("original")
                      }}
                      checked={market === "original"}
                    />
                    <span>Pierwotny</span>
                  </label>
                </div>

                <div className="col-md">
                  <p>Nieruchomości</p>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="profit"
                      value="divide"
                      onChange={() => {
                        profit === "divide"
                          ? setProfit(null)
                          : setProfit("divide")
                      }}
                      checked={profit === "divide"}
                    />
                    <span>Z udziałem w przychodzie</span>
                  </label>
                  <label className="radio-wrapper">
                    <input
                      type="checkbox"
                      name="profit"
                      value="guarantee"
                      onChange={() => {
                        profit === "guarantee"
                          ? setProfit(null)
                          : setProfit("guarantee")
                      }}
                      checked={profit === "guarantee"}
                    />
                    <span>Stała stopa zwrotu</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="objects-content__grid">
              <Fade delay={450} direction={"top"} triggerOnce>
                <div className="row">
                  {filterData(data.allWpInvestment.edges).map((item, index) => (
                    <div className="col-xl-4 col-md-6" key={index}>
                      <InvestmentTile data={item} key={index} />
                    </div>
                  ))}
                </div>
              </Fade>
              {filterData(data.allWpInvestment.edges).length === 0 && (
                <div className="objects-content__grid-info">
                  <h4>Brak wyników wyszukiwania...</h4>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </section>

      <GlobalContact />
      <GlobalGuide />
    </Layout>
  )
}

const NaszeInwestycje = () => (
  <StaticQuery
    query={graphql`
      {
        allWpInvestment(sort: { fields: date, order: DESC }) {
          edges {
            node {
              uri
              id
              slug
              title
              acfInvestments {
                investmentFiltrStatus
                investmentFiltrMarket
                investmentFiltrProfit
                investmentGallery {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NaszeInwestycjeComponent data={data} />}
  />
)

export default NaszeInwestycje
